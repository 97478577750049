import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import * as React from "react";
import styled from "styled-components";

import useEventListener from "@use-it/event-listener";

import { TestIds } from "../constants/constants";
import { DownArrow } from "./index";

const BackgroundImageStyled = styled(BackgroundImage)`
    background-attachment: fixed;
    height: calc(100vh - ${({ theme }) => theme.space.navbar});
    background-position-y: ${({ $backgroundPositionY }) => $backgroundPositionY};

    &,
    &::before,
    &::after {
        @media (max-aspect-ratio: 11/25) {
            background-position-x: 72% !important;
        }

        @media (min-aspect-ratio: 16/25) and (max-aspect-ratio: 29/25) {
            background-position-x: 58% !important;
        }

        background-position-x: 68% !important;
    }
`;

const HomeBackgroundImage = () => {
    const { placeholderImage } = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "homebg2.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `);

    const image = getImage(placeholderImage);
    const bgImage = convertToBgImage(image);

    const [backgroundPositionY, setBackgroundPositionY] = React.useState("70%");

    if (typeof window !== "undefined" && window.document) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEventListener("resize", () => {
            setBackgroundPositionY(`${79 - (5.3 * (window.innerWidth / window.innerHeight))}%`);
        }, window, { passive: true });
    }

    return (
        <BackgroundImageStyled
            Tag="div"
            {...bgImage}
            preserveStackingContext
            $backgroundPositionY={backgroundPositionY}
            key={backgroundPositionY}
            data-testid={TestIds.HomeBackgroundImage}
        >
            <DownArrow />
        </BackgroundImageStyled>
    );
};

export default HomeBackgroundImage;