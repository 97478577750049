
const color = {
    beachOrange: "#EF7F42",
    beachYellow: "#FDD77B",
};

const Theme = {
    color: {
        navbarBrand: color.beachOrange,
        footer: "#717171",
        showRowEven: "#252735",
        showRowOdd: "#5B627E",
        shadedBackground: "rgba(0,0,0,0.7)",
    },
    space: {
        vertical: -1,
        more: -1,
        footer: "96px",
        navbar: "3.25rem"
    },
    stroke: (stroke, color) => {
        let textShadow = "";
    
        for (let i = 0; i <= stroke * 2; i++) {
            for (let j = 0; j <= stroke * 2; j++) {
                textShadow += `${i - stroke}px ${j - stroke}px 0 ${color}, `;
            }
        }
    
        return textShadow.substring(0, textShadow.length - 2);
    }
};

const vertical = 0.75;
Theme.space.vertical = `${vertical}rem`;
Theme.space.more = `${vertical * 2}rem`;

export default Theme;