export const MILLISECONDS_IN_A_MINUTE = 60000;

export const isDatePast = (date, durationMinutes) => {
    if (Object.prototype.toString.call(date) !== "[object Date]") {
        date = new Date(date);
    }

    if (isNaN(date)) {
        throw new Error("Invalid date.");
    }

    return new Date(date.getTime() + (durationMinutes * MILLISECONDS_IN_A_MINUTE)) < Date.now();
};

export const scrollToTargetAdjusted = (element, offset = 0) => {
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
};