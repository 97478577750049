import PropTypes from "prop-types";
import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import { titleCase } from "title-case";

import BeachSunset from "../themes/beach-sunset";
import { BodyBackgroundImage, Footer, NavBar, Seo } from "./index";

const pageNamesOrdered = [
    "home",
    "about",
    "shows",
    "members",
    "videos",
    "photos",
    "contact"
];

const FullHeight = styled.div`
    padding-top: ${props => props.$hasPaddingTop ? props.theme.space.more : 0};
    min-height: calc(100vh - ${props => props.theme.space.navbar} - ${props => props.theme.space.footer});
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const Layout = ({ children, title, location }) => {
    const pageName = location.pathname.replace(/\//g, "");
    const pageTitle = title ? title : titleCase(pageName);
    const homeIndexOf = pageNamesOrdered.indexOf("home");
    const pageIndexOf = pageNamesOrdered.indexOf(pageName);
    const pageIndex = pageIndexOf === -1 ? homeIndexOf + 1 : pageIndexOf;

    return (
        <ThemeProvider theme={BeachSunset}>
            <Seo title={pageTitle} />
            {pageTitle !== "Home" && (
                <BodyBackgroundImage
                    currentPageIndex={pageIndex}
                    backgroundStartIndex={homeIndexOf + 1}
                    backgroundEndIndex={pageNamesOrdered.length - 1}
                />)}
            <FullHeight $hasPaddingTop={pageTitle !== "Home"}>
                <NavBar pageNames={pageNamesOrdered} />
                {children}
            </FullHeight>
            <Footer />
        </ThemeProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;