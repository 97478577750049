import React from "react";
import { Block } from "react-bulma-components";
import styled from "styled-components";

import { ShowRow, Subtitle } from "./index";

const ShowRows = styled.ol`
    list-style: none;
    > :nth-child(${props => props.$isStyleReversed ? "odd" : "even"}) { background-color: ${props => props.theme.color.showRowEven} }
`;

const ShowType = ({ title, shows, isTitleHidden, isStyleReversed }) => {
    return (
        <Block>
            <section id={`shows${title}`} aria-label={`${title} Shows`} >
                { !isTitleHidden && (<Subtitle>{title}</Subtitle>)}

                <ShowRows name="show-rows" $isStyleReversed={isStyleReversed}>
                    {shows.map((show) => {
                        return <ShowRow show={show} key={show.date} />;
                    })}
                </ShowRows>
            </section>
        </Block>
    );
};

export default ShowType;
