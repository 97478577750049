import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Columns, Element } from "react-bulma-components";
import styled from "styled-components";

const MemberMeta = styled.div`
    padding: 0.5rem;
    max-width: 432px;
    margin-left: auto;
    margin-right: auto;
    background-color: ${props => props.theme.color.shadedBackground};
`;

const MemberName = styled(Element)`
    color: ${props => props.theme.color.beachOrange};
`;

const ClickToZoom = styled.button`
    display: inline-block;
    cursor: zoom-in;
    
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
`;

const MemberSquare = ({ imagePreview, name, role, index, onClick, id, alt }) => {
    const imagePreviewData = getImage(imagePreview);

    return (
        <Columns.Column desktop={{ size: "one-quarter" }} tablet={{ size: "half" }}>
            <ClickToZoom id={id} onClick={onClick}>
                <GatsbyImage image={imagePreviewData} alt={alt} loading={index > 1? "lazy" : "eager"} />
                <MemberMeta>
                    <MemberName renderAs="p" display="block" textSize="5" textWeight="bold">{name}</MemberName>
                    <Element renderAs="p" display="block" textSize="6" textWeight="light" textColor="grey-lighter">{role}</Element>
                </MemberMeta>
            </ClickToZoom>
        </Columns.Column>
    );
};

export default MemberSquare;