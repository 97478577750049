import React from "react";
import Lightbox from "react-image-lightbox";

import { scrollToTargetAdjusted } from "../functions/helpers";

const onLightboxError = (imageSrc, srcType, errorEvent) => {
    if (process.env.NODE_ENV === "development") {
        console.log(imageSrc);
    }
    else {
        return;
    }
};

const ImageGallery = ({ uniqueGalleryName, photosLarge, children }) => {
    const [imageIndex, setImageIndex] = React.useState(0);
    const [isOpen, setIsOpen] = React.useState(false);

    const openLightbox = (clickedIndex) => {
        setImageIndex(clickedIndex);
        setIsOpen(true);
    };

    const closeLightbox = () => {
        setIsOpen(false);
        const imageElement = document.getElementById(`${uniqueGalleryName}${imageIndex}`);
        const navbarHeight = document.querySelector(".navbar").offsetHeight;
        const offset = (window.innerHeight - navbarHeight - imageElement.offsetHeight) / 2;
        scrollToTargetAdjusted(imageElement, offset);
    };

    const moveNext = () => {
        setImageIndex((imageIndex + 1) % photosLarge.length);
    };

    const movePrev = () => {
        setImageIndex((imageIndex + photosLarge.length - 1) % photosLarge.length);
    };

    return (
        <>
            {React.Children.map(children, (child, i) => {
                return React.cloneElement(child, {
                    onClick: () => openLightbox(i),
                    id: `${uniqueGalleryName}${i}`
                });
            })}
            
            {isOpen && (
                <Lightbox
                    mainSrc={photosLarge[imageIndex].url}
                    nextSrc={photosLarge[(imageIndex + 1) % photosLarge.length].url}
                    prevSrc={photosLarge[(imageIndex + photosLarge.length - 1) % photosLarge.length].url}
                    onCloseRequest={() => closeLightbox()}
                    onMoveNextRequest={() => moveNext()}
                    onMovePrevRequest={() => movePrev()}
                    onImageLoadError={() => onLightboxError()}

                    // Workaround for https://github.com/frontend-collective/react-image-lightbox/issues/184
                    reactModalProps={{ appElement: undefined }}
                    ariaHideApp={false}

                    // Workaround for https://github.com/frontend-collective/react-image-lightbox/issues/124
                    imageTitle={photosLarge[imageIndex].alt}
                />
            )}
        </>
    );
};

export default ImageGallery;
