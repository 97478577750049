import React from "react";
import { Block } from "react-bulma-components";
import styled from "styled-components";

const StyledH2 = styled.h2`
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    text-shadow: ${props => (props.theme.stroke(2, "rgba(0, 0, 0, 0.3)"))};
    text-transform: uppercase;
`;

const Subtitle = ({ children }) => {
    return (
        <Block>
            <StyledH2>
                {children}
            </StyledH2>
        </Block>
    );
};

export default Subtitle;
