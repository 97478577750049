import { Container } from "react-bulma-components";
import styled from "styled-components";

const ContainerMobile = styled(Container)`
    @media screen and (max-width: 768px) {
        --moreSpace: ${props => props.theme.space.more};
        margin-left: var(--moreSpace);
        margin-right: var(--moreSpace);
    }
`;

export default ContainerMobile;
