import { graphql } from "gatsby";

export const TestIds = {
    BodyBackgroundImage: "BodyBackgroundImage",
    HomeBackgroundImage: "HomeBackgroundImage",
    DownArrow: "DownArrow",
};

export const fragmentBlurredPlaceholder = graphql`
    fragment blurredPlaceholder on File {
        childImageSharp {
            gatsbyImageData(
                placeholder: BLURRED
            )
        }
    }
`;

export const fragmentLargeLightboxImage = graphql`
    fragment fragmentLargeLightboxImage on File {
        childImageSharp {
            fluid(maxWidth: 2000, maxHeight: 1200, fit: INSIDE) {
                src
            }
        }
    }
`;

export const fragmentVideoData = graphql`
    fragment videoData on Video {
        title
        youtubeVideo {
            title
            videoId
            localThumbnail {
                childImageSharp {
                    gatsbyImageData (
                        placeholder: BLURRED
                        height: 170
                        aspectRatio: 1.7778
                        transformOptions: { cropFocus: CENTER }
                    )
                }
            }
        }
    }
`;
