import * as React from "react";
import { Animate, AnimateKeyframes } from "react-simple-animate";
import styled from "styled-components";

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useEventListener from "@use-it/event-listener";

import { TestIds } from "../constants/constants";

const GradientOverlay = styled.div`
    height: 100%;
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.97) 95%, rgba(0, 0, 0, 1) 100%); /* W3C */
`;

const DownArrowWrapper = styled.div`
	bottom: 0;
	position: absolute;
	width: 100%;
	text-align: center;
`;

const SvgDownArrow = styled(FontAwesomeIcon)`
    color: #A2A9D0;
    font-size: 2.5rem;
    text-decoration: none;
    margin: auto;
`;

const DownArrow = () => {
    const [playFade, setPlayFade] = React.useState(false);

    if (typeof window !== "undefined" && window.document) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEventListener("scroll", () => {
            if (document.documentElement.scrollTop === 0) {
                if (playFade) {
                    setPlayFade(false);
                }
            }
            else if (!playFade) {
                setPlayFade(true);
            }
        }, window, { passive: true });
    }

    return (
        <GradientOverlay aria-hidden={true} data-testid={TestIds.DownArrow}>
            <DownArrowWrapper>
                <AnimateKeyframes
                    play
                    iterationCount="infinite"
                    direction="alternate"
                    easeType="ease-out"
                    duration={1}
                    keyframes={[
                        "transform: translateY(0)",
                        "transform: translateY(-1em)",
                    ]}
                >
                    <Animate
                        play={playFade}
                        duration={0.5}
                        start={{ opacity: 1, filter: "blur(0)" }}
                        end={{ opacity: 0, filter: "blur(10px)" }}
                    >
                        <a href="#videos">
                            <SvgDownArrow icon={faChevronDown} />
                        </a>
                    </Animate>
                </AnimateKeyframes>
            </DownArrowWrapper>
        </GradientOverlay>
    );
};

export default DownArrow;