import { balanced } from "fair-lines";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Block, Columns, Element } from "react-bulma-components";
import styled from "styled-components";

import { BlurUp, ContainerMobile } from "./index";

const VideoBorder = styled.div`
    --moreSpace: ${props => props.theme.space.more};

    display: inline-block;

    margin-top: 0;
    margin-bottom: 0 !important;
    text-align: center;
    
    @media all and (min-width:768px) {
        padding: var(--moreSpace) var(--moreSpace) 0 var(--moreSpace);
        background-color: rgba(91, 98, 126, 0.062);
    }

    & > * {
        max-width: 1280px;
    }
`;

const IframeVideoPlayer = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const VideoColumnLink = styled.a`
`;

const BlockFeatured = styled(Block)`
    position: relative;
    aspect-ratio: 16 / 9;

    & >:nth-child(1) {
        height: 100%;
    }
`;

const DivVideoTitles = styled.div`
    margin-top: .5rem;
    display: flex;
    justify-content: center;
    flex-flow: wrap;
`;

const VideoTitle = styled(Element)`
    display: block;
    text-shadow: ${props => (props.theme.stroke(2, "rgba(0, 0, 0, 0.4)"))};
    white-space: pre;
`;

// Shiva on the keyboard:
// -*/+++++++++

const VideosContainer = ({ videosList, ...props }) => {
    const youtubeVideo = videosList.featured.videos[0].youtubeVideo;
    const thumbnail = getImage(youtubeVideo.localThumbnail);
    const videos = videosList.videos;

    return (
        <Block {...props}>
            <ContainerMobile>
                <VideoBorder>
                    <BlockFeatured>
                        <BlurUp placeholderImage={thumbnail.placeholder.fallback} eventElementSelector="#videoPlayer" eventName="load">
                            <IframeVideoPlayer
                                name="videoPlayer"
                                title="Video Player"
                                src={`https://www.youtube-nocookie.com/embed/${youtubeVideo.videoId}?showinfo=0&rel=0&autohide=1`}
                                allow="encrypted-media; picture-in-picture; autoplay"
                                allowFullScreen={true}
                            />
                        </BlurUp>
                    </BlockFeatured>
                    <Block>
                        <Columns mb="0" multiline={true} mobile={{ size: 7 }} centered={true}>
                            {getColumns(videos)}
                        </Columns>
                    </Block>
                </VideoBorder>
            </ContainerMobile>
        </Block>
    );
};

function getColumns(videos) {
    const content = [];

    for (let i = 0; i < videos.length; i += 2) {
        const video = videos[i];

        content.push(
            <Columns.Column size="half" key={video.youtubeVideo.videoId}>
                <Columns multiline={false} breakpoint="mobile" >
                    {createLinkPair(i, videos)}
                </Columns>
            </Columns.Column>
        );
    }

    return content;
}

function getWrappedVideoTitleString(title) {
    const half = title.length / 2;
    const MAX_CHARS_PER_LINE = 32;

    if (title.length <= MAX_CHARS_PER_LINE) {
        return title;
    }

    const left = title.indexOf("(");
    const right = title.indexOf(")");

    if (left !== -1 && right !== -1) {
        if (left < half) {
            if (right < half) {
                return title.replace(")", ")\n");
            }

            if (right - left <= MAX_CHARS_PER_LINE) {
                return title.replace("(", "\n(");
            }
        }
        else if (right - left <= MAX_CHARS_PER_LINE) {
            return title.replace("(", "\n(");
        }
    }

    return balanced(title, { width: MAX_CHARS_PER_LINE }).join("\n");
}

/*
* Groups video links in pairs so that mobile columns display sequentially horizontally instead of vertically.
*/
function createLinkPair(i, videos) {
    const arr = [];

    for (let j = i; j < i + 2 && j < videos.length; j++) {
        const { title, youtubeVideo } = videos[j];
        const thumbnail = getImage(youtubeVideo.localThumbnail);
        const videoTitleId = title.replace(/\s/g,"");

        arr.push(
            <Columns.Column key={youtubeVideo.videoId}>
                <VideoColumnLink 
                    href={`https://www.youtube-nocookie.com/embed/${youtubeVideo.videoId}?showinfo=0&rel=0&autohide=1&autoplay=1`} 
                    target="videoPlayer"
                    aria-labelledby={videoTitleId}
                >
                    <GatsbyImage image={thumbnail} alt={`Thumbnail link to play ${youtubeVideo.title}`} />
                    <DivVideoTitles>
                        <VideoTitle id={videoTitleId} renderAs="p" mobile={{ textSize: 7 }}>{getWrappedVideoTitleString(title.toLocaleUpperCase())}</VideoTitle>
                    </DivVideoTitles>
                </VideoColumnLink>
            </Columns.Column>
        );
    }

    return arr;
}

export default VideosContainer;