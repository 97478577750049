import React from "react";
import styled from "styled-components";

const DivCenterGap = styled.div`
    max-height: 10vh;
    flex: 1;
`;

const FlexTopGap = () => {
    return (
        <DivCenterGap />
    );
};

export default FlexTopGap;