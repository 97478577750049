import React from "react";
import { Animate } from "react-simple-animate";
import styled from "styled-components";

const ChildrenWrapperAbsolute = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transform: translateZ(0px);
`;

const ImgPlaceholder = styled.img`
    width: 100%;
    height: 100%;
    object-fit: fill;
`;

// gatsby-plugin-image's blur-up technique: gatsby-plugin-image/src/gatsby-ssr.tsx
// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-image/src/gatsby-ssr.tsx

const BlurUp = ({ placeholderImage, children }) => {
    const [playBlurUp, setPlayBlurUp] = React.useState(false);

    return (
        <>
            <Animate
                play={playBlurUp}
                duration={0.5}
                start={{ opacity: 1 }}
                end={{ opacity: 0 }}
            >
                <ImgPlaceholder
                    aria-hidden="true"
                    data-placeholder-image=""
                    decoding="async"
                    src={placeholderImage}
                    alt=""
                />
            </Animate>
            <Animate
                play={playBlurUp}
                duration={0.25}
                start={{ opacity: 0 }}
                end={{ opacity: 1 }}
            >
                <ChildrenWrapperAbsolute onLoad={() => { setPlayBlurUp(true); }}>
                    {children}
                </ChildrenWrapperAbsolute>
            </Animate>
        </>
    );
};

export default BlurUp;
