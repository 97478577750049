import * as React from "react";
import { Block, Container, Content } from "react-bulma-components";
import styled from "styled-components";

const FooterStyled = styled.footer`
    text-align: center;
    height: ${props => props.theme.space.footer};
    width: 100%;
    display: flex;
    align-items: center;
`;

const ContentFooter = styled(Content)`
    color: ${props => props.theme.color.footer};
`;

const FooterCustom = () => {
    return (
        <Block>
            <FooterStyled>
                <Container>
                    <ContentFooter renderAs="p">
                        {`© ${new Date().getFullYear()} Too Tall Tyler`}
                    </ContentFooter>
                </Container>
            </FooterStyled>
        </Block>
    );
};

export default FooterCustom;