import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";

import { isDatePast } from "../functions/helpers";
import { ShowType } from "./index";

const moveOldShows = (shows, setShows) => {
    const upcomingLength = shows.upcoming.length;

    for (let i = 0; i < shows.upcoming.length; i++) {
        const show = shows.upcoming[i];

        if (isDatePast(show.date, show.duration)) {
            shows.past.unshift(show);
            shows.upcoming.splice(i--, 1);
        }
        else {
            break; // Because pre-sorted.
        }
    }

    if (shows.upcoming.length < upcomingLength)
        setShows(shows);
};

export const fragmentShowData = graphql`
    fragment showData on ShowsJson {
        date
        displayDate
        displayTime
        duration
        venue {
            name
            address2
            address1
            phone
        }
    }
`;

const ShowsContainer = ({ isCompact }) => {
    const data = useStaticQuery(graphql`
        query QueryShows {
            shows: showsSection {
                upcoming { ...showData }
                past { ...showData }
            }
        }
    `);

    const [shows, setShows] = React.useState(
        {
            upcoming: data.shows.upcoming,
            past: data.shows.past
        }
    );

    moveOldShows(shows, setShows);

    const isUpcomingOdd = shows.upcoming.length % 2 > 0;
    const isPastEmpty = shows.past.length === 0;

    return (
        <>
            <ShowType
                title={"Upcoming"}
                isTitleHidden={isCompact || isPastEmpty || shows.upcoming.length === 0}
                shows={shows.upcoming}
            />
            {!isCompact && !isPastEmpty && (<ShowType
                title="Past"
                shows={shows.past}
                isStyleReversed={isUpcomingOdd}
            />)}
        </>
    );
};

export default ShowsContainer;