import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import * as React from "react";
import styled from "styled-components";

import { TestIds } from "../constants/constants";

const BackgroundImageStyled = styled(BackgroundImage)`
    background-position: ${props => props.$backgroundPosition}%;
    background-attachment: fixed;
    position: fixed !important;
    width: 100%;
    height: calc(100vh - ${props => props.theme.space.navbar});
    top: ${props => props.theme.space.navbar};
    z-index: -1;
`;

function getMovingBackgroundPosition(currentPageIndex, backgroundStartIndex, backgroundEndIndex) {
    let numerator = (currentPageIndex - backgroundStartIndex);
    numerator = (numerator === 0) ? numerator : numerator + 1;

    let denominator = (backgroundEndIndex - backgroundStartIndex) + 1;

    return Math.max(0, Math.trunc((numerator / denominator) * 100));
}

const BodyBackgroundImage = ({ currentPageIndex, backgroundStartIndex, backgroundEndIndex }) => {
    const { placeholderImage } = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "beachblur_r.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `);

    const image = getImage(placeholderImage);
    const bgImage = convertToBgImage(image);

    return (
        <BackgroundImageStyled
            Tag="div"
            $backgroundPosition={getMovingBackgroundPosition(currentPageIndex, backgroundStartIndex, backgroundEndIndex)}

            {...bgImage}
            preserveStackingContext
            data-testid={TestIds.BodyBackgroundImage}
        />
    );
};

export default BodyBackgroundImage;