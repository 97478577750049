import React from "react";
import { Block } from "react-bulma-components";
import styled from "styled-components";

import { FlexTopGap } from "./index";

const StyledH1 = styled.h1`
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    text-shadow: ${props => (props.theme.stroke(3, "rgba(0, 0, 0, 0.171)"))};
    text-transform: uppercase;
`;

const Title = ({ children }) => {
    return (
        <>
            <Block>
                <StyledH1>
                    {children}
                </StyledH1>
            </Block>
            <FlexTopGap />
        </>
    );
};

export default Title;
