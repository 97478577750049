import * as React from "react";
import { Block, Columns, Element, Hero } from "react-bulma-components";
import styled from "styled-components";

const HeroRow = styled(Hero)`
    background-color: ${props => props.theme.color.showRowOdd};
`;

const ColumnsShows = styled(Columns)`
    line-height: 1.1;
    padding-top: ${props => props.theme.space.more};
    padding-bottom: ${props => props.theme.space.more};
`;

const ShowRow = ({ show: { displayDate, displayTime, venue: { name: venueName, address1, address2, phone } } }) => {
    return (
        <HeroRow renderAs="li">
            <Block>
                <ColumnsShows desktop={{ display: "flex", only: true }} vCentered={true}>
                    <Columns.Column textAlign="left" mobile={{ textAlign: "center" }} textWeight="semibold" textFamily="sans-serif" size="one-quarter">
                        <Element textSize={4} textAlign="right" mobile={{ textAlign: "center" }}>{displayDate}</Element>
                        <Element textSize={6} textAlign="right" mobile={{ textAlign: "center" }}>{displayTime}</Element>
                    </Columns.Column>
                    <Columns.Column textAlign="center" textWeight="light" textSize={4} size="half">
                        <p>{venueName}</p>
                    </Columns.Column>
                    <Columns.Column textAlign="right" mobile={{ textAlign: "center" }} textWeight="light" size="one-quarter">
                        <Element textAlign="left" mobile={{ textAlign: "center" }}>{address1}</Element>
                        <Element textAlign="left" mobile={{ textAlign: "center" }}>{address2}</Element>
                        <Element textAlign="left" mobile={{ textAlign: "center" }}>{phone}</Element>
                    </Columns.Column>
                </ColumnsShows>
            </Block>
        </HeroRow>
    );
};

export default ShowRow;