import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import { Columns } from "react-bulma-components";
import styled from "styled-components";

const ClickToZoom = styled.button`
    display: inline-block;
    cursor: zoom-in;

    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
`;

const PhotoColumn = ({ imagePreview, alt, index, onClick, id }) => {
    const imagePreviewData = getImage(imagePreview);

    return (
        <Columns.Column size="one-third" tablet={{ size: "half" }} mobile={{ size: "full" }}>
            <ClickToZoom id={id} onClick={onClick}>
                <GatsbyImage image={imagePreviewData} alt={`${alt}`} loading={index > 1? "lazy" : "eager"}/>
            </ClickToZoom>
        </Columns.Column>
    );
};

export default PhotoColumn;