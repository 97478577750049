import { Link } from "gatsby";
import * as React from "react";
import { Navbar } from "react-bulma-components";
import styled from "styled-components";

const NavbarBrand = styled(Navbar.Brand)`
    > * { color: ${props => props.theme.color.navbarBrand}; }
`;

const NavBar = ({ pageNames }) => {
    const [isActive, setIsActive] = React.useState(false);

    React.useEffect(() => {
        document.onclick = (event) => {
            const burger = document.querySelector(".navbar-burger");
            const navbar = document.querySelector("nav.navbar");

            if (burger.classList.contains("is-active") && !navbar.contains(event.target)) {
                burger.click();
            }
        };
    }, []);

    return (
        <>
            <Navbar fixed="top" active={isActive}>
                <NavbarBrand textWeight="bold" textSize={4}>
                    <Navbar.Item to="/" renderAs={Link}>TOO TALL TYLER</Navbar.Item>
                    <Navbar.Burger onClick={() => { setIsActive(!isActive); }} aria-label="Menu"/>
                </NavbarBrand>
                <Navbar.Menu>
                    <Navbar.Container align="right">
                        {pageNames.map(page => {
                            return (
                                <Navbar.Item to={`/${page === "home" ? "" : page + "/"}`} textAlign="center" activeClassName="is-active" renderAs={Link} key={page}>
                                    {page.toLocaleUpperCase()}
                                </Navbar.Item>
                            );
                        })}
                    </Navbar.Container>
                </Navbar.Menu>
            </Navbar>
        </>
    );
};

export default NavBar;